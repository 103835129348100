.App {
  /*text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
    width: 80px; height: 80px; margin-top: 200px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo-sub {
    animation: App-logo-spin infinite 5s linear;
    width: 80px; height: 80px; margin-top: 0px;
  }
}

.toastie{
  z-index: 900000 !important;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(255,255,255,0.9); /* Black w/ opacity */
}
.modal.open{
  display: block;
}
.modal.closed{
  display: none;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 800px;
}

.navigationBar{
  position: fixed;
  width: 100%;
  height: 60px;
  margin-bottom: 40px;
  background-color: #2DB7F0;
 

}
.navigationBar:before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(10px);
  
}
.headerLogo{
  width: 200px;
  max-width: 250px;
}
.App-header {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  
  font-size: calc(10px + 2vmin);
  color: #000;
}
.headerIcon{
  width: 30px;
  margin-left: 10px;
  margin-bottom: -10px;
}
.App-link {
  color: #2DB7F0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropBox{
  padding: 60px;
  border: 4px dashed #2DB7F0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
label {
  font-size: 14px;
}
input{
  width: 100%;
  padding: 10px;
}







/* dam grid */
.damGrid{
  clear: both;
  width: auto; margin-left: auto; margin-right: auto;
}

.root {
  display: 'flex'
}
.gridList {
  width: 300px
}
.icon {
  color: 'rgba(255, 255, 255, 0.54)'
}


/* Navbar and drawer styles */

.navbar-collapse {
  max-height: 100%;
  padding-left: 0;
  padding-right: 0;
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .navbar {
  margin-bottom: 0;
  background-color: #2DB7F0;
  border-radius: 0px;
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .navbar-nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.header .navbar {
  margin-bottom: 0;
  background-color: #2DB7F0;
  border-bottom: 3px #2DB7F0 solid;
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1);
  position: relative;
  min-height: 60px;
  margin-bottom: 20px;
}

.navbar-nav { padding-right: 30px; }

/* Menu LI items and children */

.navbar-nav {
  list-style: none;
  margin-top: 55px;
  padding-right: 20px;
}

li.nav-item {
  position: relative;
  font-size: 1em;
  float: left;
  height: 50px;
  padding-left: 30px; /* space between menu items */
  text-align: left;
}

a.nav-item-child {
  font-size: 1em;
  font-family: 'Oswald', sans-serif !important;
  color: #000;
  line-height: 15px;
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1);
  text-decoration: none;
  
    height: 100%;
    line-height: 3;
    display: block;
}
a.nav-item-chile-img{
  height: 100% !important;
    line-height: 1;
    display: block;
}

.navbar-nav>li.nav-item>a:hover {
  margin-bottom: 10px;
  border-bottom: 2px solid #2DB7F0;
  padding-bottom: 3px;
  color: #edf6fa;
}

header .nav-item-child:focus, .header .nav-item-child:hover { background: inherit }

/* MAX-WIDTH 767px */
@media (max-width:767px) {

.header .menu-container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 0;
  margin-right: 0
}

.header .navbar>.container, .header .navbar>.container>.nav-collapse {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0
}

.header .navbar>.container { width: auto }



.header .nav-item-hover:after, .link:after, .work .work-overlay:before { content: " " }

.header .navbar-toggle {
  width: 45px;
  height: 35px;
  border: none;
  margin: 35px 0;
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 31px;
  margin-right: 35px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.header .navbar-toggle .toggle-icon {
  position: relative;
  width: 21px;
  height: 1px;
  display: inline-block;
  background: #fff;
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .navbar-toggle .toggle-icon:after, .header .navbar-toggle .toggle-icon:before {
  position: absolute;
  left: 0;
  background: #fff;
  content: " "
}

.header .navbar-toggle .toggle-icon:before {
  width: 10px;
  height: 1px;
  bottom: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .navbar-toggle .toggle-icon:after {
  width: 16px;
  height: 1px;
  top: -5px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .navbar-toggle:hover .toggle-icon {
  background: #2DB7F0;
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .navbar-toggle:hover .toggle-icon:after, .header .navbar-toggle:hover .toggle-icon:before {
  width: 21px;
  height: 1px;
  background: #2DB7F0;
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .header .logo-img-main, .header .nav-item.active .nav-item-hover:after, .header .nav-item:hover .nav-item-hover:after {
  transition-duration: .4s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.7, 1, .7, 1)
}

.header .navbar-toggle:hover .toggle-icon.is-clicked { background: rgba(81,87,105,0) }
}

/* MIN-WIDTH 768px */
@media (min-width: 768px) {

.navbar-toggle { display: none; }
}


/* MAX-WIDTH 991px */


  .header .navbar-nav {
    margin: 0;
    float: left
  }
  
  .header .navbar-nav .nav-item { float: none }
  
  .header .menu-container:after, .header .menu-container:before {
    content: " ";
    display: table
  }
  
  .header .menu-container:after { clear: both }
  
  .header .logo .logo-img-main { display: none }
  
  .header .logo .logo-img-active { display: inline-block }
  
  .header .nav-item-child {
    line-height: 1.4;
    padding: 12px 12px 12px 15px
  }
  
  .header .nav-item-hover:after {
    position: absolute;
    top: 19px
  }
  
  /*======= TOGGLE MENU ======*/
  
  #toggle, #toggle + label { display: inherit; }
  
  #toggle {
    width: 45px;
    height: 40px;
    opacity: 0;
    border: none;
    margin: 35px 0;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    float: right;
    padding: 9px 10px;
    margin-top: 12px;
    margin-right: 35px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .menu-mobile {
    
    text-align: center;
    background: #2DB7F0;
    color: #FFF;
    width: 100%;
    height: 0px;
    overflow: hidden;
    margin-top: 40px;
    transition: height 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  }
  
  #toggle:checked ~ .menu-mobile { height: 300px; }
  
  #toggle + label {
    position: absolute;
    top: 12px;
    left: 20px;
    border: none;
    color: #ffffff;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  #toggle + label:after {
    content: '\2630';
    font-size: 1.7em;
  }
  
  #toggle:hover + label { color: #003878; }
  
  #toggle:checked ~ .message { height: 150px; }
  
  #toggle:checked + label:after {
    content: '\2573';
    font-size: 1.4em;
  }
  
  

  /* END Navbar and drawer styles */








  

  





  .MuiAppBar-colorPrimary{
    background-color: #2DB7F0 !important;
  }
  
  .clearBoth{
    clear: both;
    height: 50px;
  }
  
  .ReactModal__Overlay{
    z-index: 100000;
  }
  
  .navTitle{
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 20px;
    color: #013878;
    text-align: left;
    width: 100%;
    padding: 10px 0 10px 0;
  }
  
  .nav-item-parent{
    color:  #013878;
    text-decoration: none;
  }
  
  .sortArrows{
    font-size: 12px;
    color: #013878;
    font-weight: 500;
  }
  
  .navListItem {
    padding: 0px !important;
  }
  .navListItem svg{
    margin-left: 10px;
  }
  .navListItem:hover{
    background-color: #edf6fa;
  }
  .navListItem.bottom{
    margin-top:auto;
  }
  .navListItemFocus {
    background-color: #eef0f1;
  }
  .poweredBy{
    width: 50%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }
  .poweredByCopy{
    margin-bottom: 20px;
  
  }
  .poweredByTitle{
    width: 50%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }
  
  .hideMe{
    display: none;
  }
  .showMe{
    display: block;
  }
/*   
  .avatar{
    position: fixed;
    right: 0;
    margin-right: 30px;
    margin-top: 10px;
    z-index: 200000;
    
  } */



  /* end menu */



  .twoColWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    
  }

  .twoColWrapper input{
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .twoColWrapper .item{
      width: 50%; 
      padding: 20px;
  }

  .App{
    width: 100%;
  }

  .total-card-container {
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 3px;
    padding: 0 15px;
    background: #fff;
    padding-bottom: 20px;
  }
  .twoColWrapper.total-card-container{
    width: 98%;
    margin-left: auto;
    margin-right: auto;
  }

 

  .subHeading{
    text-align: left;
    
    color: #212b36;
    width: 98%;
    margin-left: auto;
    margin-right: 30px;
  }
  
  .content{
    text-align: left;
    color: #212b36;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    
      
  }
  .subHeading label, .content label, .pageTitle {
    
    margin-left: 0px;
  
    font-weight: 500;
  
    color: #212b36;
    font-size: 1.8rem;
    
  }

  .damGrid {
    min-height: 400px;
    border: 1px solid #ebeff3;
  }

  .myFooter{
    bottom: 0;
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  .poweredBy{
    width: 50%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }
  .poweredByCopy{
    margin-bottom: 20px;
  
  }
  .poweredByTitle{
    width: 50%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }
  .item label{
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }

  .avatarIcon{
    border: 2px solid #94d8f7;
    border-radius: 50%;
    width: 40px;
    cursor: pointer;
    z-index:100
    
  }

  .accountMenu{
    padding: 10px;
    background-color: #fff;
    border: 2px solid #ccc;
    width: 200px;
    position: fixed;
    margin-left: -150px;
    margin-top: -10px;
    z-index: 500001;
    cursor: pointer;
    color: #000;

  }
  .accountMenuWrapper{
    top: 0;
    left: 0 !important;
    width: 100% !important;
    height: 100vh;
    position: fixed;
    z-index: 50000;
  

  }
 

  .snowflake{
    text-align: center;
    position: fixed;
    width: 100%;;
    height: 100%;
    background:rgba(255,255,255,0.7);
    z-index: 2000000;
    top: 60px;
    left: 0;
  }
  
  
  
  
  
  
  .snowflakeMini{
    text-align: center;
    position: fixed;
  
    height: 50px;
    background:rgba(255,255,255,0.7); 
    
    z-index: 20000;
  
  
    width: 48% !important;
    flex-grow:3; 
    margin-top: 5px;
    margin-bottom: 5px;
  
  }
  .App-logoMini {
    animation: App-logo-spin infinite 5s linear;
    width: 30px; height: 30px;  margin-bottom: auto;
    margin-left: 50%;
    margin-right: auto;
    margin-top: 5px;
    opacity: 1;
  
  
  }
  
  .tempEz{
    width: 100%;
    height: 100vh;
  }

  .loginMessage{
    margin-left: auto;
    margin-right: auto;
    width: 98%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 3px;
    background: #fff;
  }


  .previewPane{
    display: flex;
    flex: 1 1 200px;
    flex-direction: column;
    padding: 10px;
    min-height: 100px;
    text-align: center;
    border: 1px solid #ebeff3; 
    justify-content: space-around;
    /* padding: 10px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: 2px solid #ebeff3; */
  }
  .previewPaneItem{
    
    
    border: 1px solid #ccc;
    max-width: 100px;
    max-height: 100px;
    text-align: center;
  
  }

  .copyBackground{
    display: flex;
    margin: 5px;
    cursor: pointer;
    color: #2DB7F0;
    font-size: 16px;
    width: 90%;
    text-align: center;
  }

  .previewImages{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    

  }
  .largePreview{
    margin-left: auto;
    margin-right: auto;
  }
  .previewPane textarea{
    
    color: #000;
    font-size: 10px;
    resize: none;
    height: 70px;
    border: none;
    cursor: pointer;
  }

  .copiedAlert{
      width: 100%;
      text-align: center;
      color: green;
      font-weight: bold;
      margin-top: 10px;
      height: 20px;
  }
  .previewTitle{
      width: 100%;
      text-align: center;
      color: #013878;
      font-weight: bold;
      margin-top: 10px;
      height: 28px;
      border-bottom: 1px solid #ebeff3;
  }

  .fixedItem{
    position: -webkit-sticky; 
    position: sticky;
    top: 65px;
  }

  .titleIcon{
    margin-left: 10px;
  }

  input[type='checkbox']{
    width: 30px;
    height: 30px;
  }

  .pagination{
    width: auto;
    text-align: center;
    margin-top: 20px;
  }
  .codeTemp{
    display: none;
  }
  .pagination button, .pagination input, .pagination select{
    padding: 10px;
  }
  .totalCount{
    text-align: center;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
  }

  .leftBlock{
    text-align: left;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  
  .leftBlock span{
    color: #006fbb;
  }
  
  .leftBlock .grey{
    color: #637381;
    font-size: 14px;
  }

  .previewImage{
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-height: 70vh;
  }


  .actionButton{
  
    border-radius: 3px;
    font-size: .95rem;
    font-weight: 300;
    border-color: #2DB7F0;
    background: linear-gradient(180deg,#2DB7F0,#06aef7);
    color: #fff;
    margin-top: 5px;
    text-align: center;
    width: auto;
    margin-right: 5px;
    padding: 5px 7px 5px 7px;
    cursor: pointer;
    display: flex;
    align-self: flex-start;
  }

  .buttonIcon{
    width: 15px !important;
    vertical-align: middle;
    margin-top: 0px;
    font-weight: bold;
    margin-right: 5px;
    fill: currentColor;
  }

  .buttonsWrapper{
    display: flex;
    justify-content: left;
  }

  .copiedAlert2{
    width: 100%;
    text-align: right;
    color: green;
    font-weight: bold;
    margin-top: 10px;
    height: 20px;
}
.copiedAlert3{
  width: 100px;
  text-align: center;
  color: green;
  font-weight: bold;
  margin-top: -40px;
  height: 40px;
  position: fixed;
  padding: 10px;
  background-color: #48e086;
  color: #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px 0 0 #48e086, 0 1px 0 2px rgba(22,29,37,.1), 0 0 0 0 transparent;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  z-index: 10000000;


  
}

.buttonsWrapper textarea:focus, .copyBackground:focus {
  outline: none !important;
  border: none;
}

.clearFilter{
  border-radius: 3px;
  padding: 5px 15px 5px 15px;
  font-size: .95rem;
  font-weight: 300;
  box-shadow: inset 0 1px 0 0 #2DB7F0, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;
  border-color: #2DB7F0;
  background: linear-gradient(180deg,#2DB7F0,#06aef7);
  color: #fff;
  margin-top: 0px;
  cursor: pointer;
  margin-left: 10px;
}

tbody tr:hover {
	background-color: #edf6fa
}















.parentGallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 3px;
    padding: 0 0px;
    background: #fff;
}

.childGallery {
  flex: 1 1 300px; 
  padding: 10px;
  border: 1px solid #edf6fa;
  min-height: 100px;
  max-width: 100%;
  text-align: center;
}
.childGallery.selected{
  border: 1px solid #42d826;
  width: 15%;
  flex: 1 0 15%;
  max-width: 15%;
  margin-right: 20px;
}

.childGallery div{
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  margin-bottom: 0px;
  padding: 0px !important;
}


.childGallery img, .childGallery .none{
    max-height: 300px;
    margin-top: 10px;
    border-radius: 20px;
    max-width: 90%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 30px;
}

.childGallery .none{
  padding-top: 90px !important;
  min-height: 200px;
}
.childGallery img:hover, .childGallery .none:hover, .previewImage:hover {
  background-color: #edf6fa;
}

.childGallery.grid{
  max-width: 100%;
  margin: 0px;
}

.actionButton.cancel{

  border-color: #da2c2c;
    background: linear-gradient(180deg,#f90e2e,#ec264a);
}

.actionButton.green{

  border-color: #7aec6d;
    background: linear-gradient(180deg,#7aec6d,#8cdd83);
}

.previewImageFull{
  width: auto;
}

.smallPreview{
  width: 20%;
  padding: 10px;
  height: auto;
  overflow: scroll;
  align-self: flex-start;
  padding-top: 0px;
  padding-right: 0px;
}
.smallPreviewDivide{
  width: 5%;
  text-align: center;
  margin-bottom: auto;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 80px;
}
.gridOfTemplates{
  width: 75%;
    border-radius: 3px;
    min-height: 600px;
}
.firstInstruction{
  font-size: 18px;
  margin-right: auto;
  margin-top: auto; 
  margin-bottom: auto;
}

.selectInstructions{
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
}
.selectInstructions span{
  color: #023b7b;
  font-weight: bold;
}

.circleBefore{
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background-color: #2DB7F0;
  text-align: center;
  margin-right: 10px;
  color: #fff;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}

.unselectedSku{
  background-color: unset;
}

.selectedSku{
  background-color: #e0f4fd;
}

.previewMerchGridselected, .previewMerchGridunselected{
  width: 14%;
  margin-right: 2%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid silver;
  margin-top: 20px;
  text-align: center;
}
.previewMerchGridselected{
  background-color: #e0f4fd;
}
.previewMerchGridunselected{
  background-color: #fff;
}
.previewMerchGridselected img, .previewMerchGridunselected img{
  max-width: 90%;
  cursor: pointer;
}
.previewMerchGridhidden{
  display: none;
}
.peLabel{
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}

.peLabel.selected{
  background-color: rgb(200, 230, 248);
}
.peLabel.selected{
  background-color: #fff;
}

table.total-card-container td{
  cursor: pointer;
}

.separator{
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}


.gridOfTemplatesPicker{
  width: 75%;
  height: auto;
  border-radius: 3px;
}
.clearFilterPicker{
  border: 1px solid #ddd;
  box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  border-color: #ddd;
  background: linear-gradient(180deg,#f9fafb,#f9fafb);
  background-color: #f9fafb;
  color: #000;
  padding: 3px 5px 3px 5px !important;
  float: right;
}

.addOpacity{
  opacity: 0.33;
}
.fullOpacity{
  opacity: 1;
}

.pickerStyle{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}
.pickerStyleWrapper{
  border: 3px solid #2DB7F0;
  border-radius: 20px;
  height: 70vh;
  overflow: scroll;
}
.closePicker{
  float: right;
  cursor: pointer;
}
.separatorGen{
  border-bottom: 4px solid #013878;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.previewGenBox{
  width: '100%';
  text-align: center;
  padding: 10px;
  border:1px solid rgba(63,63,68,.1);
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}

.greyButton, .selectedVars button{
  border: 1px solid #ddd;
  box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  border-color: #ddd;
  background: linear-gradient(180deg,#f9fafb,#f9fafb);
  background-color: #f9fafb;
  color: #000;
  padding: 3px 5px 3px 5px !important;
}

.greyButton:hover{
  
  color: rgb(0, 111, 187);
}

.preLabel{
  padding: 5px;
  margin-bottom: 10px;
}


.noResults {
  text-align: center;
  font-size: 18px;
  padding: 10px;
  color: #2DB7F0;
  font-weight: bold;

}

.videoThumb{
  margin: 20px !important;
  height: 300px;
}

input[type=radio] {
  -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
}

.miniLoaderWrapper{
  height: 30px;
}

.miniLoader{
  
}

.merchLoader{
  position: relative;
}



/*  animation */
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50% !important;
  margin-left: -10px !important;
  margin-top: 50%;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.smLoginButton{
  width: auto;
  padding: 5px 20px;
  border-radius: 20px;
  display: flex;
  margin-top: 5px !important;
  border: 2px solid #94d8f7;
}
.avatar{
  position: fixed;
  right: 0;
  margin-right: 30px;
  margin-top: 13px;
  z-index:100
}